<template>
    <div class="p-6 md:p-12 bg-gray-100 rounded-2xl mt-12">
        <div class="fui-i">
            <h3 class="h4 mb-4">Rent amount</h3>

            <div class="fui-field-container fui-label-above-input">
                <label class="fui-label" for="weeklyAmount">Weekly rent amount</label>

                <div class="fui-input-container flex bg-gray-300 rounded-lg">
                    <span class="flex items-center justify-center w-8 text-black">$</span>
                    <input id="weeklyAmount" v-model="weeklyAmount" class="fui-input rounded-l" type="number" @blur="weeklyChanged">
                </div>
            </div>


            <h3 class="h4 mt-6 mb-4">Moving in costs</h3>

            <div class="grid grid-cols-2 gap-6">
                <div class="fui-field-container fui-label-above-input">
                    <label class="fui-label" for="rentAdvance">2 weeks rent advance</label>

                    <div class="fui-input-container flex bg-gray-300 rounded-l">
                        <span class="flex items-center justify-center w-8 text-black">$</span>
                        <input id="rentAdvance" v-model="rentAdvance" class="fui-input" type="number">
                    </div>
                </div>

                <div class="fui-field-container fui-label-above-input">
                    <label class="fui-label" for="bond">Bond</label>

                    <div class="fui-input-container flex bg-gray-300 rounded-lg">
                        <span class="flex items-center justify-center w-8 text-black">$</span>
                        <input id="bond" v-model="bond" class="fui-input" type="number">
                    </div>
                </div>


                <div class="fui-field-container fui-label-above-input col-span-2">
                    <label class="fui-label" for="moveInCost">Total move in cost</label>

                    <div class="fui-input-container flex bg-gray-300 rounded-lg">
                        <span class="flex items-center justify-center w-8 text-black">$</span>
                        <input id="moveInCost" v-model="moveInCost" class="fui-input" type="number">
                    </div>
                </div>
            </div>

            <h3 class="h4 mt-6 mb-4">Rental period</h3>

            <div class="grid grid-cols-2 gap-6">
                <div class="fui-field-container fui-label-above-input">
                    <label class="fui-label" for="dailyAmount">Rental period</label>

                    <VueDatePicker
                        v-model="rentalPeriod"
                        locale="en-AU"
                        format="dd/MM/yyyy"
                        input-class-name="fui-input"
                        position="left"
                        :range="true"
                        :partial-range="false"
                        :month-change-on-scroll="false"
                        :enable-time-picker="false"
                        :auto-apply="true"
                    />
                </div>

                <div class="fui-field-container fui-label-above-input">
                    <label class="fui-label" for="dailyAmount">Total amount</label>

                    <div class="fui-input-container flex bg-gray-300 rounded-lg">
                        <span class="flex items-center justify-center w-8 text-black">$</span>
                        <input id="totalForPeriod" v-model="totalForPeriod" class="fui-input" type="number">
                    </div>
                </div>
            </div>

            <h3 class="h4 mt-6 mb-4">Rent payments</h3>

            <div class="grid grid-cols-1 sm:grid-cols-3 gap-6">
                <div class="fui-field-container fui-label-above-input">
                    <label class="fui-label" for="dailyAmount">Daily</label>

                    <div class="fui-input-container flex bg-gray-300 rounded-lg">
                        <span class="flex items-center justify-center w-8 text-black">$</span>
                        <input id="dailyAmount" v-model="dailyAmount" class="fui-input" type="number" @blur="dailyChanged">
                    </div>
                </div>

                <div class="fui-field-container fui-label-above-input">
                    <label class="fui-label" for="monthlyAmount">Monthly</label>

                    <div class="fui-input-container flex bg-gray-300 rounded-lg">
                        <span class="flex items-center justify-center w-8 text-black">$</span>
                        <input id="monthlyAmount" v-model="monthlyAmount" class="fui-input" type="number" @blur="monthlyChanged">
                    </div>
                </div>

                <div class="fui-field-container fui-label-above-input">
                    <label class="fui-label" for="yearlyAmount">Yearly</label>

                    <div class="fui-input-container flex bg-gray-300 rounded-lg">
                        <span class="flex items-center justify-center w-8 text-black">$</span>
                        <input id="yearlyAmount" v-model="yearlyAmount" class="fui-input" type="number" @blur="yearlyChanged">
                    </div>
                </div>
            </div>

            <button class="btn btn-primary mt-10" type="button" @click.prevent="weeklyChanged">Calculate</button>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

const weeklyAmount = ref(null);
const dailyAmount = ref(null);
const monthlyAmount = ref(null);
const yearlyAmount = ref(null);

const rentalPeriod = ref(null);
const rentalDays = ref(null);
const totalForPeriod = ref(null);

const updateTotalForPeriod = () => {
    if (!dailyAmount.value || !rentalPeriod.value) {
        return;
    }

    totalForPeriod.value = (dailyAmount.value * rentalDays.value).toFixed(2);
};

// Move in costs
const rentAdvance = ref(null);
const bond = ref(null);
const moveInCost = ref(null);

const updateMoveInCosts = () => {
    rentAdvance.value = (weeklyAmount.value * 2).toFixed();
    bond.value = (weeklyAmount.value * 4).toFixed();
    moveInCost.value = (weeklyAmount.value * 6).toFixed();
};

// On change functions
const weeklyChanged = () => {
    dailyAmount.value = (weeklyAmount.value / 7).toFixed(2);
    monthlyAmount.value = (weeklyAmount.value / 7 * 365 / 12).toFixed(2);
    yearlyAmount.value = (weeklyAmount.value / 7 * 365).toFixed();

    updateTotalForPeriod();
    updateMoveInCosts();
};

const dailyChanged = () => {
    weeklyAmount.value = (dailyAmount.value * 7).toFixed();
    monthlyAmount.value = (weeklyAmount.value / 7 * 365 / 12).toFixed(2);
    yearlyAmount.value = (weeklyAmount.value / 7 * 365).toFixed();

    updateTotalForPeriod();
    updateMoveInCosts();
};

const monthlyChanged = () => {
    weeklyAmount.value = (monthlyAmount.value * 12 / 365 * 7).toFixed();
    dailyAmount.value = (weeklyAmount.value / 7 * 365 / 12).toFixed(2);
    yearlyAmount.value = (weeklyAmount.value / 7 * 365).toFixed();

    updateTotalForPeriod();
    updateMoveInCosts();
};

const yearlyChanged = () => {
    weeklyAmount.value = (yearlyAmount.value / 365 * 7).toFixed();
    dailyAmount.value = (weeklyAmount.value / 7 * 365 / 12).toFixed(2);
    monthlyAmount.value = (weeklyAmount.value / 7 * 365 / 12).toFixed(2);

    updateTotalForPeriod();
    updateMoveInCosts();
};

watch(rentalPeriod, () => {
    const [start] = rentalPeriod.value;
    const [end] = [...rentalPeriod.value].reverse();

    const diffInMilliseconds = Math.abs(new Date(start) - new Date(end));
    rentalDays.value = Math.round(diffInMilliseconds / (24 * 60 * 60 * 1000)) + 1;

    updateTotalForPeriod();
});
</script>

<style type="scss">
.dp__theme_light {
    --dp-primary-color: #EE1C25;
    --dp-border-color-hover: #EE1C25;
    --dp-text-color: #5C574F;
}

.dp__menu {
    border: 1px solid #5C574F;
}
</style>
