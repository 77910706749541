<template>
    <div ref="streetViewEl"></div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { Loader } from '@googlemaps/js-api-loader';

const props = defineProps({
    position: {
        type: Object,
        required: true,
    },
    apiKey: {
        type: String,
        default: 'AIzaSyCv2HOM1E369ssDDg5iGfPVHmF0fnhv5zY',
    },
});

const streetViewEl = ref(null);

onMounted(() => {
    // Setup the Google Maps JS API loader
    const loader = new Loader({
        apiKey: props.apiKey,
        version: 'weekly',
    });

    // Load the Google Maps JS API then initialise a new a map
    loader.load().then(() => {
        const panorama = new google.maps.StreetViewPanorama(streetViewEl.value, {
            position: {
                lat: parseFloat(props.position.lat),
                lng: parseFloat(props.position.lng),
            },
            zoom: 1,
        });
    });
});
</script>
