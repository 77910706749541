<template>
    <div class="addthis_toolbox addthis_32x32_style">
        <div class="flex flex-col gap-1 p-6">
            <a class="addthis_button_facebook flex items-center gap-x-2">Share on Facebook</a>
            <a class="addthis_button_twitter flex items-center gap-x-2">Share on Twitter</a>
            <a class="addthis_button_mailto flex items-center gap-x-2">Share via email</a>
            <a class="addthis_button_link flex items-center gap-x-2">Copy link</a>
        </div>
    </div>
</template>

<script setup>
import { onMounted } from 'vue';

const loadAddThis = () => {
    if (typeof addthis !== 'undefined') {
        addthis.toolbox('.addthis_toolbox');
        return;
    }

    const addthisScript = document.createElement('script');
    addthisScript.setAttribute('src', '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-549294da5d292515');
    document.body.appendChild(addthisScript);
};

onMounted(() => {
    loadAddThis();
});
</script>
