<template>
    <div ref="wrapper">
        <slot :items="items"></slot>
    </div>
</template>

<script setup>
import {
    ref, watch, watchEffect, onMounted,
} from 'vue';
import { useElementBounding } from '@vueuse/core';
import anime from 'animejs/lib/anime';

const props = defineProps({
    options: {
        type: Object,
        default() {
            return {};
        },
    },
    delay: {
        type: Number,
        default: 0,
    },
    duration: {
        type: Number,
        default: 500,
    },
    margin: {
        type: Number,
        default: 100,
    },
});

const wrapper = ref(null);
const items = ref([]);

const animation = ref(null);

onMounted(() => {
    const options = {
        targets: items.value,
        duration: props.duration,
        delay: anime.stagger(props.duration * 0.4),
        autoplay: false,
        ...props.options,
    };

    animation.value = anime(options);
});

const visible = ref(false);
const { top } = useElementBounding(wrapper);

const unwatch = watch(top, () => {
    visible.value = top.value <= (window.innerHeight - props.margin || document.documentElement.clientHeight);
});

watchEffect(() => {
    // If the element is within the viewport
    if (visible.value) {
        setTimeout(() => {
            // Trigger the animation
            animation.value.play();
        }, props.delay);

        // Clean up watcher
        unwatch();
    }
});
</script>
