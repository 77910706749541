<template>
    <div>
        <slot :active-property="activeProperty" :update-active-property="updateActiveProperty"></slot>
    </div>
</template>

<script setup>
import { ref } from 'vue';

const activeProperty = ref(null);

const updateActiveProperty = (data) => {
    const property = fetch(`/properties/card?id=${data.id}`)
        .then((response) => {
            return response.text();
        }).then((html) => {
            activeProperty.value = html;
        });
};
</script>
