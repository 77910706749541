// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/scss/style.scss';
import '@splidejs/splide/css/core';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';

// An excellent, responsive and lightweight lightbox
import 'spotlight.js';

import lax from 'lax.js';

import formieCsrf from './components/formie-csrf';

// ================================================
// SUI Components
// ================================================

// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================
import { createApp } from 'vue';

// Import all globally-available components here. Be responsible please.
import {
    Dialog,
    DialogPanel,
    DialogTitle,
    DialogDescription,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    TransitionRoot,
    TransitionChild,
} from '@headlessui/vue';

import { Splide, SplideTrack, SplideSlide } from '@splidejs/vue-splide';

import AddthisLinks from '@components/AddthisLinks.vue';
import AnimeAnimation from '@components/AnimeAnimation.vue';
import AnimeListAnimation from '@components/AnimeListAnimation.vue';
import DialogTrigger from '@components/DialogTrigger.vue';
import GoogleMapEmbed from '@components/GoogleMapEmbed.vue';
import GoogleStreetViewEmbed from '@components/GoogleStreetViewEmbed.vue';
import PropertySearch from '@components/PropertySearch.vue';
import PropertyMap from '@components/PropertyMap.vue';
import LaxScrollAnimation from '@components/LaxScrollAnimation.vue';
import LazyHeader from '@components/LazyHeader.vue';
import RentCalculator from '@components/RentCalculator.vue';

createApp({
    delimiters: ['${', '}'],

    components: {
        HuiDialog: Dialog,
        HuiDialogPanel: DialogPanel,
        HuiDialogTitle: DialogTitle,
        HuiDialogDescription: DialogDescription,
        HuiDisclosure: Disclosure,
        HuiDisclosureButton: DisclosureButton,
        HuiDisclosurePanel: DisclosurePanel,
        HuiTabGroup: TabGroup,
        HuiTabList: TabList,
        HuiTab: Tab,
        HuiTabPanels: TabPanels,
        HuiTabPanel: TabPanel,
        HuiTransitionRoot: TransitionRoot,
        HuiTransitionChild: TransitionChild,
        SplideCarousel: Splide,
        SplideCarouselTrack: SplideTrack,
        SplideCarouselSlide: SplideSlide,
        AddthisLinks,
        AnimeAnimation,
        AnimeListAnimation,
        DialogTrigger,
        GoogleMapEmbed,
        GoogleStreetViewEmbed,
        PropertySearch,
        PropertyMap,
        LaxScrollAnimation,
        LazyHeader,
        RentCalculator,
    },

    provide() {
        lax.init();

        lax.addDriver('scrollY', () => {
            return window.scrollY;
        });

        return {
            lax,
        };
    },

    data() {
        return {
            navActive: false,
            searchActive: false,
        };
    },

    watch: {
        searchActive() {
            if (this.searchActive) {
                this.$refs.searchInput.focus();
            }
        },
    },

    mounted() {
        console.log('Mounted');

        formieCsrf();
    },
}).mount('#app');
