<template>
    <slot
        :listing-type="listingType"
        :set-listing-type="setListingType"
        :suburb="suburb"
        :set-suburb="setSuburb"
        :min-price="minPrice"
        :set-min-price="setMinPrice"
        :max-price="maxPrice"
        :set-max-price="setMaxPrice"
        :beds="beds"
        :set-beds="setBeds"
        :baths="baths"
        :set-baths="setBaths"
        :submit="submit"
    ></slot>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
    listingType: {
        type: String,
        required: true,
    },
    suburb: {
        type: String,
        required: true,
    },
    minPrice: {
        type: String,
        required: true,
    },
    maxPrice: {
        type: String,
        required: true,
    },
    beds: {
        type: String,
        required: true,
    },
    baths: {
        type: String,
        required: true,
    },
});

const listingType = ref(props.listingType);

const setListingType = (event) => {
    listingType.value = event.target.value;
};

const suburb = ref(props.suburb);

const setSuburb = (event) => {
    suburb.value = event.target.value;
};

const minPrice = ref(props.minPrice);

const setMinPrice = (event) => {
    minPrice.value = event.target.value;
};

const maxPrice = ref(props.maxPrice);

const setMaxPrice = (event) => {
    maxPrice.value = event.target.value;
};

const beds = ref(props.beds);

const setBeds = (event) => {
    beds.value = event.target.value;
};

const baths = ref(props.baths);

const setBaths = (event) => {
    baths.value = event.target.value;
};

const submit = () => {
    let url = '/properties/listings';

    if (listingType.value) {
        url = `${url}/${listingType.value}`;
    }

    if (suburb.value) {
        url = `${url}/${suburb.value}`;
    }

    const searchParams = new URLSearchParams();

    if (minPrice.value) {
        searchParams.append('minPrice', minPrice.value);
    }

    if (maxPrice.value) {
        searchParams.append('maxPrice', maxPrice.value);
    }

    if (beds.value) {
        searchParams.append('beds', beds.value);
    }

    if (baths.value) {
        searchParams.append('baths', baths.value);
    }

    if (searchParams.toString().length) {
        url = `${url}?${searchParams.toString()}`;
    }

    window.location.href = url;
};
</script>
