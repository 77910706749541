<template>
    <div ref="mapEl"></div>
</template>

<script setup>
import {
    ref, onMounted,
} from 'vue';
import { Loader } from '@googlemaps/js-api-loader';

const emit = defineEmits(['change']);

const props = defineProps({
    center: {
        type: Object,
        required: true,
    },
    markers: {
        type: Array,
        default: () => {
            return [];
        },
    },
    zoom: {
        type: Number,
        default: 12,
    },
    fitBounds: {
        type: Boolean,
        default: false,
    },
    apiKey: {
        type: String,
        default: 'AIzaSyCv2HOM1E369ssDDg5iGfPVHmF0fnhv5zY',
    },
});

const mapEl = ref(null);

onMounted(() => {
    // Setup the Google Maps JS API loader
    const loader = new Loader({
        apiKey: props.apiKey,
        version: 'weekly',
    });

    // Load the Google Maps JS API then initialise a new a map
    loader.load().then(() => {
        const map = new google.maps.Map(mapEl.value, {
            center: {
                lat: parseFloat(props.center.lat),
                lng: parseFloat(props.center.lng),
            },
            zoom: props.zoom,
        });

        if (!props.markers.length) {
            return;
        }

        const bounds = new google.maps.LatLngBounds();

        props.markers.forEach((marker) => {
            let markerOptions = {
                id: marker.id,
                position: {
                    lat: parseFloat(marker.lat),
                    lng: parseFloat(marker.lng),
                },
                map,
            };

            if (marker.icon) {
                markerOptions = {
                    ...markerOptions,
                    icon: marker.icon,
                };
            }

            const mapMarker = new google.maps.Marker(markerOptions);

            mapMarker.addListener('click', () => {
                emit('change', marker);
            });

            bounds.extend(mapMarker.getPosition());
        });

        if (props.markers.length > 1 && props.fitBounds) {
            map.fitBounds(bounds);
        }
    });
});
</script>
